import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteLivingWillModal,
  IWillRegistrationColumn,
  IWillRegistrationRow,
  IWillRegistrationPayload,
  IWillRegistrationPagination,
} from '../../datatypes/WillRegistration';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteWillRegistrationData } from '../../redux/slices/willRegistrationSlice';
import { PageOptions } from '../../constants/Common';
import moment from 'moment';
import { ISortParams } from 'src/datatypes/CommonTypes';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';
import _startCase from 'lodash/startCase';

interface IProps {
  livingWillList: IWillRegistrationPayload['livingWillList'];
  deleteWillRegistrationDetails: IWillRegistrationPayload['deleteWillRegistrationStatus'];
  showDeleteModal: IShowDeleteLivingWillModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteLivingWillModal>
  >;
  setPagination: React.Dispatch<
    React.SetStateAction<IWillRegistrationPagination>
  >;
  setSort: React.Dispatch<React.SetStateAction<ISortParams>>;
}

const Listing = ({
  livingWillList,
  deleteWillRegistrationDetails,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
  setSort,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected testimonial
  const selectedTestimonialId = (rows: IWillRegistrationRow[]) => {
    setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete testimonial
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((testimonialDetails: IWillRegistrationRow) => testimonialDetails.id)
      .join(',');
    dispatch(deleteWillRegistrationData(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IWillRegistrationRow) => {
    selectedTestimonialId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: IWillRegistrationColumn[] = [
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IWillRegistrationRow) => row.actions,
      center: true,
      compact: true,
      width: '6.5rem',
      cell: (row: IWillRegistrationRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="view"
            redirectTo={`/living-will/${row?.id}`}
            permissions={['view-living-will-details']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-living-will']}
          />
        </div>
      ),
      omit: !canUserAccess(['delete-living-will', 'view-living-will-details']),
    },
    {
      id: 'user_name',
      name: 'Full Name',
      selector: (row: IWillRegistrationRow) => row.user_name,
      sortField: 'user_name',
      sortable: true,
      cell: (row: IWillRegistrationRow) =>_startCase(row.user_name),
    },
    {
      id: 'email',
      name: 'Email',
      selector: (row: IWillRegistrationRow) => row.email,
      sortField: 'email',
      sortable: true,
    },
    {
      id: 'will_code',
      name: 'Will Code',
      selector: (row: IWillRegistrationRow) => row.will_code,
      sortField: 'will_code',
      sortable: true,
    },
    {
      id: 'loaction',
      name: 'Location',
      selector: (row: IWillRegistrationRow) => row.location,
      sortField: 'location',
      sortable: true,
    },
    {
      id: 'created_at',
      name: 'Created On',
      selector: (row: IWillRegistrationRow) => row.created_at,
      sortField: 'created_at',
      sortable: true,
      width: '9.5rem',
      right: true,
      cell: (row: IWillRegistrationRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['created_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteWillRegistrationDetails?.success) {
      setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteWillRegistrationDetails?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={livingWillList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={livingWillList.data?.total}
              data={livingWillList.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillRegistrationPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillRegistrationPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              onSort={(column, direction) => {
                setSort({ orderBy: column.sortField, order: direction });
              }}
              sortServer
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteWillRegistrationDetails={deleteWillRegistrationDetails}
        />
      </div>
    </div>
  );
};

export default Listing;
